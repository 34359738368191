import axios from '@/axios'

export default {
  namespaced: true,
  state: {
    token: undefined,
    refreshToken: undefined,
    user: undefined,
  },
  mutations: {
    SET_AUTH(state, payload) {
      state.token = payload.token
      state.refreshToken = payload.refreshToken
    },
  },
  actions: {
    validateLogin({ commit }, payload) {
      return axios.post('auth/admin/sign-in', payload)
    },
    signOut({ commit }) {
      commit('SET_AUTH', {
        token: undefined,
        refreshToken: undefined,
      })
    },
    async refreshToken({ commit }, payload) {
      const res = await axios.post('auth/admin/refresh/token', body)
      commit('SET_AUTH', {
        token: res.data.token,
        refreshToken: res.data.refreshToken,
      })
      return res
    },
  },
}
