/* eslint-disable no-underscore-dangle */
import axios from 'axios'

const axiosInstance = axios.create()

// set up axios
axiosInstance.defaults.baseURL = process.env.VUE_APP_SERVER_URL

function setUpAxios(store) {
  store.subscribe((mutation, state) => {
    if (state.auth.token) {
      axiosInstance.defaults.headers.Authorization = `Bearer ${state.auth.token}`
      axiosInstance.interceptors.response.use(res => res,
        async error => {
          const originalRequest = error.config
          if (error.response.status === 401 && state.auth.token && !originalRequest._retry) {
            originalRequest._retry = true
            try {
              const { data } = await store.dispatch('auth/refreshToken', {
                refreshToken: state.auth.refreshToken,
              })
              originalRequest.headers.Authorization = `Bearer ${data.token}`
              return axiosInstance.request(originalRequest)
            } catch (e) {
              store.dispatch('auth/signOut')
            }
          }
          return Promise.reject(error)
        })
    } else {
      axiosInstance.defaults.headers.Authorization = null
      axiosInstance.interceptors.request.eject(axiosInstance.interceptors.request)
    }
  })
}

export { setUpAxios }

export default axiosInstance
