import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import createPersistedState from 'vuex-persistedstate'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import auth from './auth'
import request from './request'

Vue.use(Vuex)
const persistedState = createPersistedState({
  key:'mabpai',
  paths: ['auth'],
})

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth,
    request,
  },
  strict: process.env.DEV,
  plugins: [persistedState],

})
