export default [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    meta: {
      requiredAuth: true,
    },
  },{
    path: '/management/:id/:type',
    name: 'management',
    component: () => import('@/views/request/management.vue'),
    meta: {
      requiredAuth: true,
    },
  },
]