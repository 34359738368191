import axios from '@/axios'

export default {
  namespaced: true,
  state: {
  },
  mutations: {

  },
  actions: {
    getListRequest({ commit }, item) {
      return axios.post('request/admin', item)
    },
    getListRequestID({ commit }, id) {
      return axios.get(`request/admin/${id}`)
    },
    replyRequestID({ commit }, item) {
      return axios.patch('request/admin/comment', item)
    },
    updateRequest({ commit }, item) {
      return axios.patch('request/admin/update', item)
    },
    deleteRequest({ commit }, item) {
      return axios.post('request/admin/delete', item)
    },
    getRequestType({ commit }) {
      return axios.get('request/type')
    },
    getAllRequest() {
      return axios.get('request/')
    },
  },
}
