import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import moment from 'moment'
import abbreviate from 'number-abbreviate'
import LightGallery from 'vue-light-gallery'
import Swal from 'sweetalert2'
import { setUpAxios } from './axios'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

Vue.use(LightGallery)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.prototype.$baseURL = `${process.env.VUE_APP_SERVER_URL}/image/`
Vue.prototype.$swal = o => new Swal(o)

Vue.filter('toTimeThai', value => {
  const dataVal = value
  return moment(dataVal).locale('th').format('lll')
})
Vue.filter('DateRelative', val => `${moment(val).locale('th').fromNow()}`)

Vue.filter('toAmount', value => {
  let dataVal = value
  if (typeof dataVal !== 'number') {
    dataVal = parseFloat(dataVal)
  }

  if (dataVal > 10000) {
    return abbreviate(dataVal)
  }
  const formatter = new Intl.NumberFormat('th', { minimumFractionDigits: 0 })
  return formatter.format(dataVal)
})
Vue.config.productionTip = false
setUpAxios(store)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
